import { AxiosInstance, AxiosResponse } from 'axios';
export const GRANT_TYPE_PASSWORD = 'password';


interface TokensPair {
    accessToken: string;
    refreshToken: string;
}

interface AccessTokenInfo {
    aud: string;
    exp: string;
    iat: string;
    issuer: string;
    jti: string;
    ownerId: string;
    type: string;
    coId: string | undefined;
    ownUid: string | undefined;
}

class Index {
    api: AxiosInstance;

    clientId: string;

    clientSecret: string;

    constructor(api: AxiosInstance) {
        this.api = api;
        this.clientId = process.env.REACT_APP_API_CLIENT_ID;
        this.clientSecret = process.env.REACT_APP_API_CLIENT_SECRET;
    }


    async signIn(props): Promise<TokensPair> {

        return await this.api.fetch({
            method:'post',
            url: '/auth/login',
            data: {
                ...props,
                client_id    : this.clientId,
                client_secret: this.clientSecret,
                grant_type   : GRANT_TYPE_PASSWORD,
            }
        });
    }

    async signUp(props): Promise<AxiosResponse> {
        return await this.api.fetch({
            method:'post',
            url: '/auth/register',
            data: {
                ...props,
                client_id: this.clientId,
                client_secret: this.clientSecret,
                grant_type: GRANT_TYPE_PASSWORD,
            }
        });
    }



    async signInByPhone(phone): Promise<TokensPair> {

        return await this.api.fetch({
            method:'post',
            url: '/auth/phone',
            data: {
                phone,
                client_id    : this.clientId,
                client_secret: this.clientSecret,
                grant_type   : GRANT_TYPE_PASSWORD,
            }
        });
    }



    async signInByPhoneCheck(phone, smsCode): Promise<TokensPair> {

        return await this.api.fetch({
            method:'post',
            url: '/auth/phone/check',
            data: {
                phone,
                sms_code: smsCode,
                client_id    : this.clientId,
                client_secret: this.clientSecret,
                grant_type   : GRANT_TYPE_PASSWORD,
            }
        });
    }


    async signInByUserId(userId: string): Promise<TokensPair> {

        return await this.api.fetch({
            method:'post',
            url: '/auth/login-as-user',
            data: {
                user_id: userId,
            }
        });
    }


    async resetPasswordAndSendAdmin(id: string): Promise<TokensPair> {
        return await this.api.fetch({
            method:'get',
            url: `/users/${id}/reset-password`,
        });
    }


    async domainInfo(domain) {
        return await this.api.fetch({
            method:'get',
            url: `/auth/info/domain/${domain}`,
        });
    }

    async verify(uuid): Promise<TokensPair> {
        return await this.api.fetch({
            method:'get',
            url: `/auth/verify/${uuid}`,
        });
    }


    async signInByGoogleOAuth(clientId, tokenId): Promise<TokensPair> {
        return await this.api.fetch({
            method:'post',
            url: '/auth/google-oauth',
            data: { clientId, tokenId }
        });
    }

    async signInByFacebookOAuth(clientId, tokenId): Promise<TokensPair> {
        return await this.api.fetch({
            method:'post',
            url: '/auth/facebook-oauth',
            data: { clientId, tokenId }
        });
    }
    async signInByVkOAuth(clientId, redirectURL, tokenId): Promise<TokensPair> {
        return await this.api.fetch({
            method:'post',
            url: '/auth/vk-oauth',
            data: { clientId, redirectURL, tokenId }
        });
    }






    async requestResetPassword(params: {
        email: string;
        backUrl: string;
    }): Promise<AxiosResponse> {
        return await this.api.fetch({
            method:'post',
            url: '/auth/forgot',
            data: {
                email: params.email,
                backUrl: params.backUrl,
                client_id    : this.clientId,
                client_secret: this.clientSecret,
                grant_type   : GRANT_TYPE_PASSWORD,
            }
        });
    }


    /*
    async refreshAccessToken(params: {
        refreshToken?: string;
        escapeImpersonate?: boolean;
    } = {}): Promise<TokensPair> {
        const { refreshToken, escapeImpersonate } = params;
        console.log(6);
        const response = await this.api.post('/auth/token', {
            client_id: this.clientId,
            client_secret: this.clientSecret,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            escapeImpersonate,
        });

        const {
                  access_token: newAccessToken,
                  refresh_token: newRefreshToken,
              } = response.data;

        return {
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
        };
    }
    */
}

export default Index;
